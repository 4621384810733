
#logo{
	max-width: 70%;
}

#qr{
	max-width: 100%;
	padding: 20%;
}

#data{
	text-align: center;
}

#div_name{
	font-size: 32px;
	font-weight: bold;
}

#div_userId{
	padding-top: 60px;
	font-size: 24px;
	font-weight: bold;
}

#div_obs{
	font-size: 18px;
	font-weight: bold;
}


#data button
{
	margin-top: 50px;
	font-size: medium;
	cursor: pointer;
	height: 40px;
	border: 1px solid black;
}
